import axios from "axios";
import getConfig from "next/config";
import {
  CreateApplicationResponse,
  LoginWithToken,
  GenericResponse,
  SubmitExpenditure,
  SubmitIncome,
  SubmitProperty,
  SubmitPreferredProperty,
  SubmitQualifyingCriteria,
  CreateApplication,
  Application,
  NewApplicant,
  AddApplicantResponse,
  RemoveApplicant,
  SubmitPersonalDetails,
  VerificationResponse,
  LoginWithPassword,
  SendMessage,
  SubmitPropertyAddress,
  SubmitPropertyAgent,
  DetailedProperty,
  SubmitPropertyType,
  SubmitLeaseType,
  SubmitPropertyInformation,
  BaseRequest,
  GetApplicantResponse,
  SubmitApplicationOffer,
  CurrentUserResponse,
  SubmitCreditAndStoreCards,
  SubmitDependants,
  SubmitLoansAndMortgages,
  SubmitAddressHistory,
  ResetPassword,
  SubmitDetailedIncome,
  ApplicantPreferences,
  DeleteIncome,
  GoogleLocationResponse,
  UpdateApplicationStatus,
  SubmitPropertyForReview,
  CalculateBudget,
  CalculateDealBudget,
  AcceptQuote,
  BudgetInPrinciple,
  PropertyPaymentDetails,
  GooglePlaceDetailsResponse,
  Documents,
  CreateLandlordMondayID,
  createLandlordMondayIDResponse,
  LandlordProperty,
  GetReferralsResponse,
  Listings,
  Listing,
  GetListings,
  UpdateApplicantMainDetails,
  Applicant,
  ListingSearchSuggestion,
} from "./apiTypes";

const { publicRuntimeConfig } = getConfig();

const axiosInstance = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// API returns 200 with "$success: false" instead of http 404 code for
// resources that are not found (feature request from the client), so we
// want to manually fail requests on client side when we see failed request.
axiosInstance.interceptors.response.use((res) => {
  if (res.data.$success === false) {
    throw new Error("Request failed");
  }

  return res;
});

axiosInstance.interceptors.request.use((req) => {
  // if (res.data.$success === false) {
  //   throw new Error('Request failed');
  // }
  const token = localStorage.getItem("token");
  if (token && req.headers) {
    req.headers["Authorization"] = token;
  }
  return req;
});

export const createApplication = (data: CreateApplication): Promise<CreateApplicationResponse> =>
  axiosInstance.post("/web/v1/applications", data).then((res) => {
    !!res.data && localStorage.setItem("token", res.data.actionToken.value);
    return res.data;
  });

export const submitPersonalDetails = ({
  applicationId,
  applicantId,
  data,
}: SubmitPersonalDetails): Promise<GenericResponse> =>
  axiosInstance
    .patch(`/web/v1/applications/${applicationId}/applicants/${applicantId}/personal-details`, data)
    .then((res) => res.data);

export const submitAddressHistory = ({
  applicationId,
  applicantId,
  data,
}: SubmitAddressHistory): Promise<GenericResponse> =>
  axiosInstance
    .post(
      `/web/v1/applications/${applicationId}/applicants/${applicantId}/historical-addresses`,
      data,
    )
    .then((res) => res.data);

export const submitDetailedIncome = ({
  applicationId,
  applicantId,
  data,
}: SubmitDetailedIncome): Promise<GenericResponse> =>
  axiosInstance
    .post(`/web/v1/applications/${applicationId}/applicants/${applicantId}/income/sources`, data)
    .then((res) => res.data);

export const getApplicant = (
  applicationId: string,
  applicantId: string,
): Promise<GetApplicantResponse & GenericResponse> =>
  axiosInstance.get(`/applicants/${applicantId}`).then((res) => res.data);

export const getBrokerReferral = (
  referralId: string,
): Promise<GetApplicantResponse & GenericResponse> =>
  axiosInstance.get(`/broker-referrals/${referralId}`).then((res) => res.data);

export const getGooglePlaceDetails = (
  placeId: string,
): Promise<GooglePlaceDetailsResponse & GenericResponse> =>
  axiosInstance
    .get(`/web/v1/applications/map/google-place-details?placeId=${placeId}`)
    .then((res) => res.data);

export const submitApplicant = ({
  applicationId,
  data,
}: NewApplicant): Promise<AddApplicantResponse & GenericResponse> =>
  axiosInstance
    .post(`/web/v1/applications/${applicationId}/applicants`, data)
    .then((res) => res.data);

export const updateApplicantMainDetails = ({
  applicationId,
  applicantId,
  data,
}: UpdateApplicantMainDetails): Promise<Applicant & GenericResponse> =>
  axiosInstance
    .patch(`/web/v1/applications/${applicationId}/applicants/${applicantId}`, data)
    .then((res) => res.data);

export const acceptQuote = ({
  applicationId,
}: AcceptQuote): Promise<AddApplicantResponse & GenericResponse> =>
  axiosInstance.post(`/applications/${applicationId}/accept-quote`).then((res) => res.data);

export const acceptOffer = ({
  applicationId,
}: AcceptQuote): Promise<AddApplicantResponse & GenericResponse> =>
  axiosInstance.post(`/applications/${applicationId}/accept-offer`).then((res) => res.data);

export const deleteApplicant = ({
  applicationId,
  applicantId,
}: RemoveApplicant): Promise<AddApplicantResponse & GenericResponse> =>
  axiosInstance.delete(`/applicants/${applicantId}`).then((res) => res.data);

export const submitExpenditure = ({
  applicationId,
  applicantId,
  data,
}: SubmitExpenditure): Promise<GenericResponse> =>
  axiosInstance
    .patch(`/web/v1/applications/${applicationId}/applicants/${applicantId}/expenditure`, data)
    .then((res) => res.data);

export const submitIncome = ({
  applicationId,
  applicantId,
  data,
}: SubmitIncome): Promise<GenericResponse> =>
  axiosInstance
    .post(`/web/v1/applications/${applicationId}/applicants/${applicantId}/income`, data)
    .then((res) => res.data);

export const submitQualifyingCriteria = ({
  applicationId,
  applicantId,
  data,
}: SubmitQualifyingCriteria): Promise<GenericResponse> =>
  axiosInstance
    .patch(
      `/web/v1/applications/${applicationId}/applicants/${applicantId}/qualifying-criteria`,
      data,
    )
    .then((res) => res.data);

export const checkQualifyingCriteria = ({
  applicationId,
  data: {},
}: {
  applicationId: string;
  data: any;
}): Promise<Application> =>
  axiosInstance.post(`/applications/${applicationId}/qualifying-criteria`).then((res) => res.data);

export const submitCreditAndStoreCards = ({
  applicationId,
  applicantId,
  data,
}: SubmitCreditAndStoreCards): Promise<GenericResponse> =>
  axiosInstance
    .patch(`/web/v1/applications/${applicationId}/applicants/${applicantId}/expenditure`, data)
    .then((res) => res.data);

export const submitLoansAndMortgages = ({
  applicationId,
  applicantId,
  data,
}: SubmitLoansAndMortgages): Promise<GenericResponse> =>
  axiosInstance
    .patch(`/web/v1/applications/${applicationId}/applicants/${applicantId}/expenditure`, data)
    .then((res) => res.data);

export const submitDependants = ({
  applicationId,
  applicantId,
  data,
}: SubmitDependants): Promise<GenericResponse> =>
  axiosInstance
    .patch(`/web/v1/applications/${applicationId}/applicants/${applicantId}/expenditure`, data)
    .then((res) => res.data);

export const submitProperty = ({ applicationId, data }: SubmitProperty): Promise<GenericResponse> =>
  axiosInstance
    .patch(`/web/v1/applications/${applicationId}/property`, data)
    .then((res) => res.data);

export const submitPreferredProperty = ({
  applicationId,
  data,
  skipAppProcess,
}: SubmitPreferredProperty): Promise<GenericResponse> =>
  axiosInstance
    .patch(
      `/applications/${applicationId}/preferred-property?skipAppProcess=${skipAppProcess ? 1 : 0}`,
      data,
    )
    .then((res) => res.data);

export const getApplication = (): Promise<Application> =>
  axiosInstance.get(`/web/v1/applications/current`).then((res) => res.data);

export const getApplicationWithToken = ({
  applicationId,
  token,
}: {
  token: string;
  applicationId: string;
}): Promise<Application> => {
  const axiosInstance = axios.create({
    baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  return axiosInstance.get(`/applications/${applicationId}`).then((res) => {
    return res.data;
  });
};

export const getBudgetById = ({ budgetId }: { budgetId: string }): Promise<BudgetInPrinciple> =>
  axiosInstance.get(`/budget-in-principle/${budgetId}`).then((res) => {
    return res.data;
  });

export const shareBudget = ({
  applicationId,
  email,
}: {
  applicationId: string;
  email: string;
}): Promise<string> =>
  axiosInstance
    .post(`/applications/${applicationId}/share-budget`, { email: email })
    .then((res) => {
      return res.data;
    });

export const loginWithToken = (data: LoginWithToken): Promise<string> =>
  axiosInstance.post("/web/v1/auth/login/with-token", data).then((res) => {
    res?.data && localStorage.setItem("token", res.data.authToken.value);
    return res.data;
  });

export const getDocuments = (applicationId: string, condition: string): Promise<Documents> =>
  axiosInstance
    .get(`/applications/${applicationId}/documents?${condition}`)
    .then((res) => res.data);

export const downloadDocuments = (applicationId: string, documentId: string): Promise<any> =>
  axiosInstance
    .get(`/applications/${applicationId}/documents/${documentId}/download`, {
      responseType: "arraybuffer",
    })
    .then((res) => {
      return res;
    });

export const getApplicationReferrals = (applicationId: string): Promise<GetReferralsResponse> =>
  axiosInstance.get(`/applications/${applicationId}/referrals`).then((res) => res.data);

export const getProperty = (propertyId: string): Promise<DetailedProperty> =>
  axiosInstance.get(`/properties/${propertyId}`).then((res) => res.data);

export const getPropertyPaymentDetails = (propertyId: string): Promise<PropertyPaymentDetails> =>
  axiosInstance.get(`/properties/${propertyId}/payment-detail`).then((res) => {
    return res.data;
  });

export const submitPropertyAddress = ({
  property_id,
  data,
}: SubmitPropertyAddress): Promise<GenericResponse> =>
  axiosInstance.post(`/properties/`, data).then((res) => res.data);

export const updatePropertyAddress = ({
  property_id,
  data,
}: SubmitPropertyAddress): Promise<GenericResponse> =>
  axiosInstance.put(`/properties/${property_id}`, data).then((res) => res.data);

export const submitPropertyAgent = ({
  property_id,
  data,
}: SubmitPropertyAgent): Promise<GenericResponse> =>
  axiosInstance.put(`/properties/${property_id}`, data).then((res) => res.data);

export const submitPropertyType = ({
  property_id,
  data,
}: SubmitPropertyType): Promise<GenericResponse> =>
  axiosInstance.put(`/properties/${property_id}`, data).then((res) => res.data);

export const submitPropertyForReview = ({
  property_id,
}: SubmitPropertyForReview): Promise<GenericResponse> =>
  axiosInstance.post(`/properties/${property_id}/submit`, {}).then((res) => res.data);

export const submitLeaseType = ({ property_id, data }: SubmitLeaseType): Promise<GenericResponse> =>
  axiosInstance.put(`/properties/${property_id}`, data).then((res) => res.data);

export const submitPropertyInformation = ({
  property_id,
  data,
}: SubmitPropertyInformation): Promise<GenericResponse> =>
  axiosInstance.put(`/properties/${property_id}`, data).then((res) => res.data);

export const submitApplicationOffer = ({
  property_id,
  data,
}: SubmitApplicationOffer): Promise<GenericResponse> =>
  axiosInstance.put(`/properties/${property_id}`, data).then((res) => res.data);

export const loginWithPassword = async (data: LoginWithPassword): Promise<string> => {
  try {
    const res = await axiosInstance.post("/web/v1/auth/login", data);
    !!res.data && localStorage.setItem("token", res.data.authToken.value);
    return res.data;
  } catch (err) {
    throw new Error(
      "We don’t recognise either the email address or password. Please try again or reset your password.",
    );
  }
};

export const logout = (): Promise<void> => {
  localStorage.removeItem("token");
  return axiosInstance.delete("/web/v1/auth/logout");
};

export const createPassword = (password: string): Promise<string> =>
  axiosInstance.post(`/web/v1/auth/set-password`, { password }).then((res) => res.data);

export const forgotPassword = (email: string): Promise<GenericResponse> =>
  axiosInstance.post(`/web/v1/auth/forgot-password`, { email }).then((res) => res.data);

export const resetPassword = (data: ResetPassword): Promise<GenericResponse> =>
  axiosInstance.post(`/web/v1/auth/reset-password`, data).then((res) => res.data);

export const getCurrentUser = (): Promise<CurrentUserResponse> =>
  axiosInstance.get(`/web/v1/auth/me`).then((res) => res.data);

export const generateVeriffUrl = (
  applicantId: string,
): Promise<VerificationResponse & GenericResponse> =>
  axiosInstance
    .post(`/web/v1/identity-verification/generate-veriff-url/${applicantId}`)
    .then((res) => res.data);

export const generateIncomeVerificationUrl = ({
  applicationId,
  applicantId,
}: BaseRequest): Promise<VerificationResponse & GenericResponse> =>
  axiosInstance
    .post(`/web/v1/applications/${applicationId}/applicants/${applicantId}/account-score-url`)
    .then((res) => res.data);

export const generateFinternUrl = ({
  applicantId,
}: BaseRequest): Promise<VerificationResponse & GenericResponse> =>
  axiosInstance.post(`/fintern/create-fintern-url/${applicantId}`).then((res) => res.data);

export const sendMessage = (data: SendMessage): Promise<GenericResponse> =>
  axiosInstance.post(`/web/v1/contact`, data).then((res) => res.data);

export const updateApplicantPreferences = (data: SendMessage): Promise<GenericResponse> =>
  axiosInstance.post(`/web/v1/contact`, data).then((res) => res.data);

export const updateApplicantsPreferences = ({
  applicationId,
  applicantId,
  data,
}: ApplicantPreferences): Promise<GenericResponse> =>
  axiosInstance
    .post(`/web/v1/applications/${applicationId}/applicants/${applicantId}/preferences`, data)
    .then((res) => res.data);

export const deleteIncomeSource = ({
  applicationId,
  applicantId,
  data,
}: DeleteIncome): Promise<GenericResponse> =>
  axiosInstance
    .delete(`/web/v1/applications/${applicationId}/applicants/${applicantId}/income`, { data })
    .then((res) => res.data);

export const getGoogleLocationData = (placeId: string): Promise<GoogleLocationResponse> =>
  axiosInstance
    .get(`/web/v1/applications/map/google-place-details?placeId=${placeId}`)
    .then((res) => res.data);

export const calculateBudget = ({
  type,
  incomeOrPrice,
  years,
  outcode,
  estimatedHousePriceGrowth,
  estimatedIncomeGrowth,
}: CalculateBudget): Promise<any> =>
  axiosInstance
    .get(
      `/web/budget?type=${type}&incomeOrPrice=${incomeOrPrice}&years=${years}&outcode=${outcode}&estimatedHousePriceGrowth=${estimatedHousePriceGrowth}&estimatedIncomeGrowth=${estimatedIncomeGrowth}`,
    )
    .then((res) => res.data);

export const calculateDealBudget = ({
  postcode,
  propertyValue,
  currentRent,
  managedByAgent,
  estimatedHousePriceGrowth,
  years,
}: CalculateDealBudget): Promise<any> =>
  axiosInstance
    .get(
      `/landlord/calculate-budget?postcode=${postcode}&propertyValue=${propertyValue}&currentRent=${currentRent}&managedByAgent=${managedByAgent}&estimatedHousePriceGrowth=${estimatedHousePriceGrowth}&years=${years}`,
    )
    .then((res) => res.data);

export const updateApplicationStatus = ({
  applicationId,
  data,
}: UpdateApplicationStatus): Promise<GenericResponse> =>
  axiosInstance.post(`/applications/${applicationId}/submit`, data).then((res) => res.data);

export const createLandlordMondayID = ({
  data,
}: CreateLandlordMondayID): Promise<createLandlordMondayIDResponse> =>
  axiosInstance.post(`/landlords`, data).then((res) => res.data);

export const landlordPropertyToMonday = ({
  landlordMondayId,
  data,
}: LandlordProperty): Promise<GenericResponse> =>
  axiosInstance.post(`/landlords/${landlordMondayId}/property`, data).then((res) => res.data);

export const getLandlord = (email: string): Promise<any> =>
  axiosInstance.get(`/landlords?email=${encodeURIComponent(email)}`).then((res) => res.data);

export const getListings = ({ limit, offset, search }: GetListings): Promise<Listings> =>
  axiosInstance.get(`/listings?limit=${limit}&offset=${offset}&search=${search}`).then((res) => {
    return { ...res.data, limit, offset } as Listings;
  });

export const getListingItem = (id: string): Promise<Listing> =>
  axiosInstance.get(`/listings/${id}`).then((res) => {
    return res.data;
  });

export const getListingSearchSuggestions = ({
  search,
}: {
  search: string;
}): Promise<ListingSearchSuggestion[]> =>
  axiosInstance.get(`/listings/auto-complete/terms?search=${search}`).then((res) => {
    return res.data;
  });
export default axiosInstance;
